@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
@include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-range-input;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;

.grid-container {
    max-width: 1600px;
}

body {
    background: #000000;
}

.menu.simple a{
  padding: 0.7rem 1rem;
  color: #ffffff;
}

// Top Nav

.topbar-responsive {
    background: $topbar-responsive-bg;
    padding: 0.2rem 1.5rem;
    margin-top: 5px;
    //height: 250px;
    
  
    .topbar-responsive-logo {
      display:inline-block;
      //background: url(/assets/img/ridgepointbuscap250-5.png) no-repeat;
      //background-size: 150px, 150px, auto;
      color: $white;
      vertical-align: top;
      margin-top: 0px;
     // height:85px;
    }
}

.cormorant-sc-bold {
  font-size: 40px;
  font-family: "Cormorant SC", serif;
  font-weight: 700;
  font-style: normal;
}


.top-bar ul {
  background: #000000;
}

// Hero Section



.marketing-site-hero {
  background:#5c5e5f;
  opacity: 0.9;
  background: url(/assets/img/pexels-brett-sayles-2599538.png) no-repeat ;
  height: $marketing-site-hero-height;
  background-size: cover;
  display: flex;
  align-items: center;
  //height:400px;
  width: 1600 px;
  margin-bottom: 40px;

  @media screen and (min-width: 40em) {
    background-position: center center;
  }
}

.mshback {
  //background:#5c5e5f;
  opacity: 0.9;
  padding: 20px;
  border-radius: 15px 15px;
  width: 650px;
  //height: 50px;
    
  ul {
    color: #ffffff;
  }
}

.marketing-site-hero-content {
  max-width: $global-width;
  margin: 0 auto;
  padding-left: 5%;
  //padding-right: 30%;
  text-align: center;

  h1 {
    color: #ffffff;
    font-size: 38px;
    font-weight: bold;
    text-align: center;
  }

  .subheader {
    color: #ffffff;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }

  .herophone {
    font-size: 32px;
    font-weight: bold;
    color:#ffffff;
  }

  //ul {
  // color: #ffffff;
  //}

  .button.round {
    border-radius: 5000px;
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 0;
  }

  @media screen and (min-width: 40em) {
    padding-right: 0%;
  }
}

  // Mid Content Section

  .phone {
    font-weight: bold;
    font-size: 24px;
    border: 1px solid #3340f7;
    padding: 10px;
  }
  
  .mid{
      background:#282828;
      color:#ffffff;
      display:flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
      //width:1400vw;
  }
  
  .row{
      row-gap: normal;
      display:flex;
      text-align:center;
  }
  
  .marketing-site-features {
      text-align: center;
      padding: 2rem 0;
    
      .fa {
        font-size: 2rem;
        margin-bottom: 1rem;
        color: $primary-color;
      }
    
      .marketing-site-features-title {
        font-size: 1.125rem;
        margin-bottom: 1rem;
      }
  
      .marketing-site-features-desc {
        padding: 20px;
      }
    
      @media screen and (min-width: 40em) {
        text-align: left;
      }
    }
    
    .marketing-site-features-headline {
      text-align: center;
      font-size: 24px;
    }
    
    .marketing-site-features-subheadline {
      margin-bottom: 2rem;
      text-align: center;
    }
    
    
  
  .marketing-site-content-section {
      display: flex;
      flex-wrap: wrap;
      font-size: 12px;
      color: #ffffff;
    
      .button.round {
        border-radius: 5000px;
        text-transform: uppercase;
        margin-bottom: 0;
      }
    
      .marketing-site-content-section-img {
        align-self: center;
        flex: 1 0 100%;
    
        img {
          width: 100%;
        }
    
        @media screen and (min-width: 40em) {
          flex: 1 0 50%;
        }
      }
    
      .marketing-site-content-section-block {
        padding: 1rem;
        flex: 1 0 100%;
        align-self: stretch;
        background: $light-gray;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    
        @media screen and (min-width: 40em) {
          flex: 1 0 50%;
          padding: 2rem;
        }
      }
    }
    
    .marketing-site-content-section-block-header {
      font-size: 24px;
    }
    
    .marketing-site-content-section-block-subheader {
      font-size: 13px;
      color: #ffffff;
    }
    
    .line {
      color: #ffffff;
    }
  
  // Main Content Section

  .main-head{
    color:#ffffff;
    padding: 0 0 50px 50px;
    font-size: 24px;
  }

  .marketing-site-content-section {
  display: flex;
  flex-wrap: wrap;

  .button.round {
    border-radius: 5000px;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  .marketing-site-content-section-img {
    align-self: center;
    flex: 1 0 100%;

    img {
      width: 100%;
    }

    @media screen and (min-width: 40em) {
      flex: 1 0 50%;
    }
  }

  .marketing-site-content-section-block {
    padding: 1rem;
    flex: 1 0 100%;
    align-self: stretch;
    background: #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @media screen and (min-width: 40em) {
      flex: 1 0 50%;
      padding: 2rem;
    }
  }
}

.marketing-site-content-section-block-header {
  font-size: 24px;
  color:#ffffff;
}

.marketing-site-content-section-block-subheader {
  font-size: 16px;
}

.aspace{
    margin-bottom:35px;
    justify-content: center;
    display:grid;
}

// Referral Partner Section

.phone-partner {
  font-weight: bold;
  font-size: 24px;
  //border: 1px solid #3340f7;
  padding: 20px;
}

// Footer Section



.marketing-site-footer {
  background: $marketing-site-footer-bg;
  color: lighten($marketing-site-footer-bg, 40%);
  padding: 2rem 0 0;

  .marketing-site-footer-menu-social a {
    color: $white;
  }

  .column-block {
    margin-bottom: 30px;
  }

  > .row {
    margin-bottom: 1rem;
  }

  @include breakpoint(small only) {
    .columns {
      margin-bottom: 2rem;
    }
  }
}

.marketing-site-footer-name {
  color: $white;
  margin-bottom: 1rem;
  font-size: 2rem;
}

.marketing-site-footer-title {
  color: $white;
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.marketing-site-footer-block {
  display: flex;
  margin-bottom: 1rem;
  justify-content: center;

  .fa {
    font-size: 2rem;
    color: darken($marketing-site-footer-bg, 20%);
  }

  p {
    margin-left: 1rem;
    line-height: 1.125rem;
  }
}

.marketing-site-footer-bottom {
  background: darken($marketing-site-footer-bg, 20%);
  padding: 1rem 0;

  p,
  .menu {
    margin-bottom: 0;
  }

  .marketing-site-footer-bottom-links {
    justify-content: flex-end;

    a {
      color: lighten($marketing-site-footer-bg, 40%);
    }

    @include breakpoint(medium down) {
      justify-content: center;
    }
  }

  @include breakpoint(medium down) {
    text-align: center;
  }
}


// About Page

.about-main{
  color:#ffffff;
  display: flex;
  flex-wrap: wrap;
}

.main-look{
  display:flex;
  //flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  flex: 1 0 100%;
}



// Partner content

//.part {
  //color:#ffffff;
//}
  